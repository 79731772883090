import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import { FingoSnackBar } from '@fingo/lib/components/snackBars';
import { UPDATE_USER_INFO } from '@fingo/lib/graphql';
import { formatRut } from '@fingo/lib/helpers';
import { useBooleanState, useGetUser, useSelectedCompany } from '@fingo/lib/hooks';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import { LoadingButton } from '@mui/lab';
import Business from '@mui/icons-material/Business';
import Person from '@mui/icons-material/Person';
import { TitledTextField } from '@fingo/lib/components/inputs';
import { useProfileInfo } from '../../../constants';

const InfoGrid = styled(Grid)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: 15,
  padding: 30,
}));

const emptyObject = (obj) => Object.values(obj).every((value) => value === '');

const MyInfo = () => {
  const userObject = useGetUser();
  const selectedCompany = useSelectedCompany();
  const topRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState({});
  const [attributeChanges, setAttributeChanges] = useState({});
  const [snackbarOpen, toggleSnackbar] = useBooleanState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [disabledEdit, toggleDisabledEdit] = useBooleanState(true);
  const [formValues, setFormValues] = useState({});
  const [updateUserInfo, { loading }] = useMutation(UPDATE_USER_INFO, {
    onError: () => {
      setSnackbarMessage(
        'Ha ocurrido un error. Revise que los datos sean correctos e intente nuevamente.',
      );
      toggleSnackbar();
    },
    onCompleted: () => {
      setAttributeChanges({});
      setSnackbarMessage('Sus cambios fueron guardados exitosamente.');
      toggleSnackbar();
    },
  });
  const profileInfo = useProfileInfo({ setAttributeChanges });
  const userInfo = useMemo(
    () => profileInfo.filter((grid) => grid.category === 'user'),
    [profileInfo],
  );
  const companyInfo = useMemo(
    () => profileInfo.filter((grid) => grid.category === 'company'),
    [profileInfo],
  );
  const info = [
    { title: 'Información personal:', data: userInfo, icon: <Person color="primary" /> },
    { title: 'Información de la empresa:', data: companyInfo, icon: <Business color="primary" /> },
  ];
  const handleOnClick = () => {
    if (Object.keys(attributeChanges).length) {
      updateUserInfo({ variables: attributeChanges });
    } else {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    toggleDisabledEdit();
  };
  const handleCancel = () => {
    toggleDisabledEdit();
    setAttributeChanges({});
    setFormValues((prev) => ({ ...prev, ...userObject }));
  };
  useEffect(() => {
    setFormValues(
      profileInfo.reduce((acc, curr) => {
        if (curr.type === 'rut') {
          acc[curr.attributeName] = formatRut(curr.getAttribute(userObject));
        } else {
          acc[curr.attributeName] = curr.getAttribute(userObject);
        }
        return acc;
      }, {}),
    );
  }, [selectedCompany, userObject]);
  const cancelButton = (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      sx={{
        maxWidth: { xs: 'inherit', sm: 'fit-content' },
        py: 0.5,
      }}
      onClick={handleCancel}
      id="info-cancel-button"
    >
      Cancelar
    </Button>
  );
  const disableSaveButton = (
    !((emptyObject(attributeChanges) ? !emptyObject(errorMsg) : emptyObject(errorMsg)))
  );
  const mainButton = (
    <LoadingButton
      variant="contained"
      color="primary"
      size="small"
      loading={loading}
      disabled={!disabledEdit && disableSaveButton}
      onClick={handleOnClick}
      sx={{
        maxWidth: { xs: 'inherit', sm: 'fit-content' },
        py: 0.5,
      }}
      id={disabledEdit ? 'info-edit-button' : 'info-save-button'}
    >
      {disabledEdit ? 'Editar' : 'Guardar'}
    </LoadingButton>
  );
  return (
    <>
      <Stack sx={{ mt: 0, pb: 5 }}>
        <PaperHeader viewTitle="Mi información" />
        <FingoDivider />
        <Typography paragraph sx={{ mt: 2, mb: 4 }}>
          Esta es tu información y la de tu empresa que serán necesarias para operar con nosotros
        </Typography>
        <Stack spacing={2} ref={topRef}>
          {info.map(({ title, data, icon }) => (
            <InfoGrid container key={title}>
              <Stack direction="column" width="100%" spacing={2.5}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {icon}
                  <Typography variant="h5">{title}</Typography>
                </Stack>
                <Grid container direction="row" rowGap={2}>
                  {data.map((editing) => (
                    <TitledTextField
                      key={editing.title}
                      user={userObject}
                      disabledEdit={disabledEdit}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      setErrorMsg={setErrorMsg}
                      errorMsg={errorMsg}
                      {...editing}
                    />
                  ))}
                </Grid>
              </Stack>
            </InfoGrid>
          ))}
          <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            spacing={1}
            sx={{
              justifyContent: { xs: 'center', sm: 'flex-end' },
            }}
          >
            {!disabledEdit && cancelButton}
            {mainButton}
          </Stack>
        </Stack>
      </Stack>
      <FingoSnackBar
        open={snackbarOpen}
        setOpen={toggleSnackbar}
        message={snackbarMessage}
        severity="error"
      />
    </>
  );
};

export default MyInfo;
