import React, { useCallback } from 'react';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useGetUser, useTextFieldInput } from '@fingo/lib/hooks';
import { CHANGE_COMPANY, GET_USER } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { cleanAllShopping } from '@fingo/lib/apollo/helpers/shopping-cart';
import useGetMyCompanies from '@fingo/lib/hooks/useGetMyCompanies';

const MyCompanies = () => {
  const user = useGetUser();
  const [searchValue, setSearchValue] = useTextFieldInput('');
  const { companies, loadingCompanies } = useGetMyCompanies({
    first: 20,
    masterEntityNameIcontains: searchValue,
  });
  const [changeCompany, { loading: loadingChangingCompany }] = useMutation(CHANGE_COMPANY, {
    refetchQueries: [GET_USER],
    awaitRefetchQueries: true,
  });
  const handleSelectCompany = useCallback((event, company) => {
    if (!company) return;
    cleanAllShopping();
    changeCompany({ variables: { companyId: company.id } });
  }, []);

  return (
    <Grid container rowSpacing={4} sx={{ mt: 0, pb: 4 }} justifyContent="space-between">
      <PaperHeader viewTitle="Mis empresas" />
      <FingoDivider />
      <Grid container sx={{ py: 2 }}>
        <Stack direction="column" spacing={3}>
          <Typography component="div">
            En esta sección puedes administrar tus empresas y quiénes tienen acceso a ellas.
            Selecciona la empresa que quieres <br /> ver y en recuadro de abajo administra las
            personas que tienen acceso a la plataforma.
          </Typography>
          <Typography component="div" fontWeight={700}>
            Selecciona la empresa que deseas visualizar:
          </Typography>
          <FormControl variant="outlined" sx={{ minWidth: 200 }}>
            <Autocomplete
              id="selector-company"
              options={companies}
              defaultValue={user.selectedCompany}
              getOptionLabel={(option) => option.masterEntity.name
                || option.masterEntity.displayNationalIdentifier}
              disablePortal
              filterOptions={((options) => options)}
              loading={loadingChangingCompany}
              onChange={handleSelectCompany}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={searchValue}
                  onChange={setSearchValue}
                  label="Empresa"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: loadingCompanies && (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MyCompanies;
