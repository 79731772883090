import React from 'react';
import { FingoDivider } from '@fingo/lib/components/dividers';
import { PaperHeader } from '@fingo/lib/components/headers';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useTermAndConditionsSections } from '@fingo/lib/hooks';

const MyTerms = () => {
  const { loading, termSections } = useTermAndConditionsSections();
  return (
    <Grid container rowSpacing={4} sx={{ mt: 0 }} justifyContent="space-between">
      <PaperHeader viewTitle="Términos y condiciones" />
      <FingoDivider />
      {loading ? (
        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      ) : (
        termSections
      )}
    </Grid>
  );
};

export default MyTerms;
